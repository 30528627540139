.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

input[type=file]::file-selector-button {
  border: 2px solid rgba(255,255,255, 0.3) ;
  /* padding: .2em .4em; */
  color: white;
  border-radius: .2em;
  background-color: rgb(167 139 250);
  transition: 0.1s;
  margin-right: 8px;
  height: 100%; 
  box-shadow: 1px 1px 6px rgba(0,0,0,0.2);
}

input[type=file]::file-selector-button:hover {
  /* background-color: #f59e0b;
  border: 2px solid #facc15; */
  box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
